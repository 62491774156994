.TiffinService_Element{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 90px;
    height: 80px;
    cursor: pointer;
}
.TiffinService_Element>p{
 font-size: 11px;
 font-weight: 600;
 color: #606c84;
}
.TiffinService_Element>img{
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
.selectTiffin{
    background-color: rgba(0, 97, 247,0.15);
}
.TiffinServiceFiltercontainer_div{
    margin: 0;
}


