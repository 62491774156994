.Dreamhome_main_container_div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Dreamhome_container_div{
    width: 780px;
    position: relative;
}
.Dreamhome_container_div>img{
    width: 780px;
    /* margin: 50px 0 50px; */
    margin-top: 50px;
}
.Dreamhome_notification{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 23px;
    right: 30px;
    background-color: #F7F3E8;
border: 4px solid #FFFFFF;
}
.Dreamhome_notification_logo{
    /* transform: rotate(-30deg); */
    animation:rotate 0.1s infinite;
}
@keyframes rotate{
    0%{  transform: rotate(-30deg);   }
    50%{   transform: rotate(-10deg);  }
    /* 62%{   transform: rotate(-10deg);  }
    97%{   transform: rotate(0deg);  } */
    100%{  transform: rotate(-30deg);  
  }
}