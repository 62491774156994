*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.homefram_main_container_div{
    width: 100%;
    height: 470px;
    background: linear-gradient(0deg, rgba(248, 211, 25, 0.1),
    rgba(248, 211, 25, 0.1)),
    url('../../../assets/bg.png');
     /* background: linear-gradient(0deg, rgba(1, 43, 44, 0.6),
    rgba(1, 43, 44, 0.6)),
    url('https://images.adsttc.com/media/images/5ec3/1b40/b357/65cf/6100/0035/newsletter/DSC_2500.jpg?1589844773'); */
    /* background-image: url('../../../assets/admin/Home/IMAGE.png'); */
 background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items:center;
    /* text-align: center; */
    color: white;
    /* background-size: 100% 470px ; */
    object-fit: cover;
}
.homefram_main_container_div:hover{
    color: white;
    background-size: 102% 810px ;
    object-fit: cover;
    /* transition: all 500ms ease; */
    transition: background-size 0.5s ease;

}

.homefram_container_div{
    position: relative;  
    width: 100%;
    display: flex;
    justify-content: center;
}
.homefram_image_container_div{
    position: absolute;
    right: 72px;
    bottom: -152px;
    width: 217px;
}
.homefram_heading_container_div{
    width: 600px;
}
.homefram_heading_container_div>h1{
font-weight: 500;
font-size: 47px;
line-height: 55px;
color: #ADADAD;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.homefram_span_home{
    color: #FE0000;
    text-shadow:
0px 0px 25px rgba(219, 48, 48, 0.65);
}
.homefram_span_Scouts{
color: #ED6823;
text-shadow:
0px 0px 25px rgba(226, 135, 31, 0.65);
}
.homefram_span_360h{
color: #FFFFFF;
text-shadow:
0px 0px 25px rgba(194, 179, 179, 0.85);
}
.homefram_span_india{
    text-shadow:
0px 0px 25px rgba(134, 126, 126, 0.85);
color: #FFFFFF;

}
@media screen and (max-width:1100px) {
    .homefram_image_container_div {
        position: absolute;
        right: 0px;
        bottom: -152px;
        width: 223px;
    }
}
@media screen and (max-width:440px) {
    .homefram_image_container_div {
        position: absolute;
        right: 0;
        bottom: -125px;
        width: 150px;
    }
}