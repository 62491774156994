.MainContainer {
  min-height: calc(100vh - 120px);
  min-width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  top: 120px;
  background-color: white;
}
.section1 {
  width: 25%;
  background-color: #f1f1f1;
  height: calc(100vh - 120px);
  overflow-y: scroll;
}
.section2 {
  width: 75%;
  background-color: #e4e7ed;
  height: calc(100vh - 120px);
  position: relative;
  overflow: hidden;
}

/* -------------List View of User style -------------- */
.ListViewOfUser {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  margin: 2px 0px;
  width: 100%;
  height: 60px;
  padding: 5px 5%;
  cursor: pointer;
}
.ListViewOfUser:hover {
  background-color: #f0f2f5;
}
.userIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 100%;
  padding: 2px 5px;
  overflow: hidden;
  
}
.userIconInner {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
}
.userIcon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #f1f1f1;
}
.tooltipOnline {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  bottom: -2px;
  right: -2px;
}
.active {
  background-color: green;
}
.inactive {
  background-color: rgb(182, 192, 193);
}

.nameMessage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 5px;
  align-items: flex-start;
  height: 100%;
  width: 60%;
  padding: 2px;
}
.userName {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 2px;
  height: 26px;
  overflow: hidden;
  font-size: 18px;
}
.latestMessage {
  overflow: hidden;
  width: 100%;
  padding: 2px;
  font-size: 12px;
  height: 16px;
}
.lastSeenTime {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20%;
  padding: 2px;
  height: 100%;
  font-size: 14px;
}

/* --------------- Chat Veiw Of User Style ------------------------ */

.ChatViewOfUser {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

/* -------------------  Message Container ------------------------------ */
.messagesDiv {
  padding: 16px 32px 0px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
}
/* ---------------------- Sender View Style ---------------------------- */
.SenderViewDiv,
.ReceiverViewDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 28px;
}
.SenderViewDiv {
  justify-content: flex-end;
}
.SenderViewInnerDiv,
.ReceiverViewInnerDiv {
  max-width: 40%;
  padding: 16px 16px 8px;
  position: relative;
  border-radius: 15px;
}
.SenderViewInnerDiv {
  background-color: #006bc2ab;
  box-shadow: -6px 6px 0px #acd5f5;
}
.senderuserProfile,
.receiveruserProfile {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.senderuserProfile {
  position: absolute;
  top: -20px;
  right: -20px;
}

/* ---------------------- Reciever View Style ---------------------------- */
.ReceiverViewDiv {
  justify-content: flex-start;
}
.ReceiverViewInnerDiv {
  background-color: #4d8e8eba;
  box-shadow: 6px 6px 0px #4c8d8d;
}
.receiveruserProfile {
  position: absolute;
  top: -20px;
  left: -20px;
}
.message {
  padding: 4px;
}
.timeStamp {
  width: 100%;
  text-align: right;
  font-size: 12px;
  color: rgb(232, 228, 228);
}

/* -------------------- Typing Section of chat -------------------------- */

.TypingDiv {
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  background-color: #d9d9d9;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1% 5% 1% 3%;
  /* padding: 1%; */
  border-top: 2px solid #fff;
}
.userIconTypeBox {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userIconTypeBox img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.TypingDiv textarea {
  vertical-align: middle;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  resize: none;
  padding: 8px;
  margin: 0px 20px;
  font-size: 16px;
  background-color: #ffffff;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.TypingDiv textarea::-webkit-scrollbar {
  display: none;
}
.TypingDiv textarea:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.TypingDiv textarea:focus-visible {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.SendIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
}
.SendIcon svg {
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 10px;
}
.SendIcon svg:hover {
  background-color: #f78009;
}
.rcecontainerinput{
  border-radius: 5px;
  width: 100%;
  padding: 10px;
}
.MessageCont{
  width: 100%;
  height: calc(100vh - 277px);
  overflow: hidden;
}
.MessageScroll{
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.recivedMessage {
  display:flex;
  align-items: center;
  justify-content: start;
   width:100%;
   position: relative;
}
.sendMessageRig{
  justify-content: flex-end;

}
.recivedMenu{
  position: absolute;
  top: 20px;
  background-color: #fff;
  box-shadow: 0px 2px 13px rgba(5, 0, 255, 0.16);
  display: none;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 1px;
  z-index: 2;
}
.recivedMenuLeft{
  left: -77px;
}
.rce-container-mbox{
  width: 100%;
}
.recivedMessageBox{
  min-width: 150px !important;
}
.recivedMenu>div{
display: flex;
padding: 5px 15px;
gap: 10px;
justify-content: center;
align-items: center;
width: 100%;

}
.recivedMenu>div>p{
  width: 100%;
  font-size: 11px;
  cursor: pointer;
}
.recivArrowDown{
  position: relative;
  display: none;
}
.MdKeyboardArrowDown{
  cursor: pointer;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0px 2px 13px rgba(5, 0, 255, 0.16);

}
.recivedMenu>div:hover{
  background-color: #e4e7ed;
  width: 100%;
}
.recivedMessage:hover .recivArrowDown{
  display: block;

}
.recivArrowDown:hover .recivedMenu{
  display:flex;
}
.chatlist{
  background-color: #e4e7ed;
}
.chatlist:hover{
  background-color: red;
}
.UserChatBlockDelete{
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.UserChatBlockDelete>div{
  padding: 0 10px;
  cursor: pointer;
}
.UserChatBlockDelete>div>img{
  width: 30px;
  height: 30px;
}
.UserChatBlockDelete>div>p{
  font-weight: 500;
  font-size: 10px;
  line-height: 160.3%;
  text-align: right;
  letter-spacing: -0.005em;
  color: #515F68;
}
.rce-avatar-container{
  border-radius: 50px !important;
}
.BackArrow{
  display: none;
}
.ShowChat{
  display: flex;
  width: 100%;
  }
.ShowChatMobile{
display: none;
}

.enquiryLeftRight{
  display: flex;
  width: 100%;
  justify-content: end;
  padding: 3px;
}

.UserChatsDelete{
  background-color: #f78009;
  color: #fff ;
  border: 1px solid #f78009;
  border-radius: 10px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 10px;
  padding: 10px;
  height: 50px;
  max-width:370px ;
}
.UserChatsDelete>p{
  color: #fff !important;
}
.UserChatsDelete>button{
  width: 90px;
  height: 20px;
  color: #fff;
  border-radius: 2px;
  font-size: 10px;
  background-color: #0d6efd;
}

.TypingContainerDiv{
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
}
.chatDoc{
  position: absolute;
  height: 60px;
  width: 100%;
  background-color: #fff;
  bottom: -5px;
  left: 0;
  border-radius: 10px;
  
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatFileSelect{
width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.chatFileMdOutlineFileCopy{
  border: 1px solid #fff;
  width: 50%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d9d9d9;
  gap: 10px;
  padding: 10px;
  color: #fff;
  border-radius: 5px;

}
.fileBoxShadow{
  background-color: rgb(237, 104, 35);
}




.FileMdOutlineFileCopy{
  font-size: 30px;

}


.chatFileMdOutline{
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;

}
.recivedMessageFile{
  width: 300px;
  padding: 10px;
}


@media screen and (max-width:800px) {
  .section1 {
    width: 300px;
    /* background-color: #f1f1f1;
    height: calc(100vh - 91px);
    overflow-y: scroll; */
    /* display: none; */
  }

}
@media screen and (max-width:600px) {
  .section1 {
    width: 100%;
    /* background-color: #f1f1f1;
    height: calc(100vh - 91px);
    overflow-y: scroll; */
    /* display: none; */
  }
  .section2 {
    width: 100%;
    background-color: #e4e7ed;
    height: calc(100vh - 91px);
    position: relative;
    overflow: hidden;
  }
  .MessageCont{
    height: calc(100vh - 245px);

  }
  .MainContainer {
    min-height: calc(100vh - 91px);
    top: 91px;
  }
  .BackArrow{
    display: flex;
    color: #fff;
    font-size: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
  }
  .ShowChat{
  display: none;
  }
  .ShowChatMobile{
    display: 
    flex;
    width: 100%;
    }
    .UserChatsDelete>p>span{
     display: none;
    }
    .UserChatsDelete{
      position: absolute;
      right: 10px;
      border-radius:5px;
      gap: 5px;
      padding:5px;
      height: 50px;
      max-width:230px ;
    }
    .UserChatsDelete>p{
      color: #fff !important;
      font-size: 9px !important;
    }
    .UserChatsDelete>button{
      width: 80px;
      height: 20px;
      color: #fff;
      border-radius: 2px;
      font-size: 10px;
      background-color: #0d6efd;
    }
}
