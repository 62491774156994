* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#option {
    background-color: #0d6efd;
    color: white !important;
}

.Buysearch_main_container_div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50px;

}

.Buysearch_container_div {
    height: 100px;
    background-color: white;
    border-radius: 10px;
    z-index: 2;
    /* background-color: white; */
}

.Buysearch_property_container_div {
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.Buysearch_property_container_button {
    padding: 13px 20px;
    /* background-color: #004E8F; */
    cursor: pointer;
}

.Buysearch_property_container_button>a {
    font-size: 12px;
    color: #42526E;
    cursor: pointer;

}
a{
    line-height: unset;
}

.Buysearch_property_container_button:hover {
    background-color: rgba(13, 110, 253,0.7);
    color: white !important;
}

.Buysearch_search_container_div {
    display: flex;
    height: 50px;
    box-shadow: 0px 0px 10px -1px rgb(0 0 0 / 60%);
    border-radius: 0px 0px 10px 10px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 1rem;
}
.Buysearch_search_container_div>input{
    min-width: 50%;
}
#Buysearch_span {
    border-radius: 10px;
    padding: 0px 2px;
    font-size: 8px;
    color: tomato;

    animation: blinking 1.5s infinite;
    /* font-size: 20px; */
}

#Buysearch_span {}

@keyframes blinking {
    0% {
        color: red;
        background-color: rgb(5, 12, 85);
    }

    47% {
        color: #fff;
    }

    62% {
        color: transparent;
    }

    97% {
        color: transparent;
    }

    100% {
        color: #000;
        background-color: #0d6efd;
    }
}

.Buysearch_selectoption {
    border: none;
    padding: 10px;
    font-size: 12px;
    color: #42526E;
    width: 150px;
    cursor: pointer;
    border-left: 2px solid rgb(255, 255, 255) !important;
    border-top: 2px solid rgb(255, 255, 255) !important;
    border-bottom: 2px solid rgb(255, 255, 255) !important;
    border-right: 1px solid rgb(223, 217, 217) !important;

}

.Buysearch_input {
    background-color: #fff;
    border: none !;
}

.Buysearch_input:focus {
    border: none !important;
}

.Buysearch_getlocation_container_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #0d6efd;
    cursor: pointer;
    position: relative;
}

.Buysearch_voiceicon {
    font-size: 27px;
    cursor: pointer;
}

.Buysearch_locationicon {
    font-size: 20px;
    cursor: pointer;
    color: #0d6efd;
}

.Buysearch_searchbutton {
    font-size: 12px;
    cursor: pointer;
}

.Buysearch_search_mobile_container_div {
    display: none;
}

.Buysearch_search_transcript_container_div {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 30, 49, .75);
    z-index: 3;
    z-index: 100001;

}

.Buysearch_search_transcript_div {
    /* margin-top: 50vh; */
    width: 40%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    border-radius: 20px;
    background-color: rgba(0, 30, 49, 1);
}
.Buysearch_search_transcript_div>img {
    width: 200px;
    margin: 5px;
}
.Buysearch_getlocation_containerDiv{
    display: flex;
    justify-content: center;
    gap: 7px;
    align-items: center;
}
.Buysearch_property_Mobilecontainer_div{
    display: none;
}
.Buysearch_getlocation_Select{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    background-color: #f2ecec;
    padding:3px ;
    border-radius: 50px;
    box-shadow: 0px 2px 4px rgba(5, 0, 255, 0.16);
}
.Buysearch_getlocation_Select>p{
    font-size: 10px;
}
.Buysearch_locationiconImCross{
    position: absolute;
    font-size: 15px;
    color: red;
    cursor: pointer;
    top: -7px;
    right: 0;
    background-color: #e8e4e4 ;
    padding: 3px;
    border-radius: 50px;
}
@media screen and (max-width:992px) {
    .hiddenone {
        display: none;
        margin-top: 50px;
        margin-right: -50px;
        transition: all 4s ease;
        -webkit-transition: all 4s ease;
    }

}

@media screen and (max-width:600px) {
    .Buysearch_container_div {
        display: none;
    }
    
.Buysearch_voiceicon {
    font-size: 32px;
    cursor: pointer;
}

    .Buysearch_search_mobile_container_div {
        margin-top: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        box-shadow: 2px 2px 10px -1px rgb(0 0 0 / 21%);
        padding: 5px;
        border-radius: 5px;
        width: 100%;
        margin: 60px 20px 0px 20px;
    position: relative;

    }

    .Buysearch_search_mobile_container_div>input {
        background-color: #fff;
        padding: 5px;
        width: 100%;
    }

    .Buysearch_search_mobile_container_div>a {
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
    .Buysearch_search_transcript_div {
        width: 80%;
        margin: 5px;
    }
    .FcSearchSearch{
        margin-bottom: 0rem;
    }
    .Buysearch_property_Mobilecontainer_div{
        display: block;
        position: absolute;
        background-color: #fff;
        left: 0;
        top:50px;
        padding: 10px;
        border-radius: 5px;
        color: #42526e;
        cursor: pointer;
    }
    .BuysearchMobile_property_container_button{
        font-size: 13px;
        padding: 0 5px;
        border-radius: 2px;

    }
}

input:focus {
    box-shadow: none;
}

.form-control:focus {
    box-shadow: none;
}




