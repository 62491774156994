
/* ------------------------------usermainpage--------------------------------------- */
.PropertiePageDetails_main_container_div{
}
.containers{
    max-width: 1000px !important;
}
.PropertiePageDetails_container_div{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #f0f2fa;
    padding-bottom: 10px;
    z-index: 0;
}
.AccountChange_UserRegisteredAdmin {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 270px;
    height: 68px;
    background: #F5F9FF;
    border: 1px solid #1565D8;
    box-shadow: 0px 4px 14px 1px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
}

@media screen and (max-width:1520px) {
    .PropertiePageDetails_container_div{
        padding-bottom: 5px;
    }

}
@media screen and (max-width:1367px) {
    .AccountChange_UserRegisteredAdmin {
        width: 170px;
        height: 37px;
    }
    .AccountChange_UserRegisteredAdminParagraph{
        font-size: 12px;
    }
}
@media screen and (min-width: 1200px){
.containers {
    max-width: 900px;
}
}