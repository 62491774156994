.ReportDetails_mainOuterDiv{
    position: absolute;
    background-color: rgba(20, 19, 19, 0.9);
    z-index: 20;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ReportDetails_maindivCon{
    width:60%;
    /* height: 70%; */
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    z-index: 3;
    margin: 0 10px;
}
.ReportDetails_messageBox{
    width: 98%;
    height: 400px;
 
    overflow-y: scroll;
margin: 10px;
padding: 10px;
background-color: rgb(219, 212, 212);
border-radius: 10px;

}
.ReportDetails_MdClose{
    position: absolute;
    right: -20px;
    top: -20px;
    z-index: 2;
    background-color: rgb(209, 209, 209);
    color: red;width: 30px;height: 30px;
    border-radius: 40px;
    cursor: pointer;
}
.ReportDetails_message{
    height: 75%;
}
.ReportDetails_messageStatus{
   height: 25%;
    border-top: 2px dashed rgba(25, 24, 24, 0.6);
    display: flex;  
    justify-content: space-evenly;
    align-items: center;
}
.ReportDetails_userTable>img{
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
}
.ReportDetails_userTable>p{
    background-color: #4191DB;
    color: #fff;
    cursor: pointer;
}
.selectRepoetStatus{
    background-color: #F30000 !important;
    color: #fff;
    cursor: pointer;
}