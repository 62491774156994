
.MyAds_main_container_div{
    background-color:  #fff;
    margin-bottom: 10px;
    height: 347px;
}
.MyAds_postdate{ 
    font-size: 12px;
    line-height: 17px;
    margin: 5px;
    display: flex;
    justify-content: space-between;
}
.MyAds_container_div{
    display: flex;
    flex-direction: column;
    margin-top: 30px; 
    margin-left: 25px;
    gap: 10px;
    position: relative;
}
.MyAds_detailsandimages_div{
    display: flex;
    flex-direction: row;
    height: 222px;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.14);
    border-radius: 15px;
}
.MyAds_images_div{
    width: 22%;
}
.MyAds_details_container_div{
    width: 78%;
}
.MyAds_detailstype_div{
    display: flex;
    justify-content: space-between;
}
.MyAds_detailstype_div>p{
    font-size: 16px;
   width: 60%;
}
.MyAds_detailstype_div>div{
    width: 40%;
    display: flex;
    justify-content: flex-end;
    height: 40px;
}
.MyAds_propertylocation_div{
    font-size: 13px;
    border-bottom:2px solid #b15e5e;
    
    font-weight: 500;
    padding: 3px 0;
    margin: 3px 0;
}
.MyAds_prices_div{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    gap: 5px;
    margin: 5px 0;
}
.MyAds_prices_div>h6{
    font-size: 14px;
    font-weight: 600;
}
.MyAds_prices_div>p{
    font-size: 12px;
    font-weight: 600;
}

.MyAds_prices_div>p>span{
    font-size: 10px;
}
.MyAds_description_div>p{
    font-size: 12px;
    margin: 5px 0;
}
.MyAds_contactowner_button{
    width: 20%;
    margin-top: 20px;
    padding: 10px ;
    border-radius: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 17px;
    background-color: #0061F7;
    color: #fff;
}
.MyAds_contactowner_button>span{
    font-size: 17px;

}
#OwnerIcon{
    font-size: 22px;
}
.MyAds_roomdetails_more_div{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 300px;
    padding: 10px;
    color: #0061F7;
}
.MyAds_roomdetails_div{
    display: flex;
    gap: 10px;
    padding: 10px 0;
}
.MyAds_iconparagraph_div{
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}
.MyAds_iconmore_div{
    display: flex;
    gap: 5px;
    align-items: center;
    color: #0061F7;
    cursor: pointer;
}
.MyAds_iconparagraph_div>p{
    font-size: 12px;
}
.MyAds_icons_div{
    border: 1px solid;
    border-radius: 50px;
    font-size: 13px;
}
.MyAds_slider_container_div{
    position: absolute;
    top: -10px;
    left: 20px;
    width: 100px;
}
.sliders_container {
    width: 250px;
    height: 250px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.118) 22.9%),
      linear-gradient(270deg, rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 15.96%, rgba(0, 0, 0, 0) 33.17%),
      linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 10.02%, rgba(0, 0, 0, 0) 25.18%);
    /* border: 5px solid #FFFFFF; */
    box-shadow: 0px 4px 16px 3px rgba(0, 0, 0, 0.2);
    border-radius: 22px !important;
  }
  
  .sliders_carosal-div img {
    background-size: cover;
    display: flex;
    object-fit: cover;
    height: 240px;
    /* border: ; */
  }
.opacity{
    opacity: 0.6;
}
.Myads_EditToActive{
  position: absolute;
  right: 2%;
  top: 19%;
  z-index: 1;
  display: flex;
  gap: 10px;
}
.redforToggleButton{
    background-color: rgb(249, 38, 38);

}
.Myads_EditToActive>button,.Myads_EditAas>button,.Myads_EditAas_button{
 color: #fff;
 background: #0061F7;
box-shadow: 0px 4px 4px rgba(0, 142, 255, 0.12);
border-radius: 50px !important;
padding: 6px 10px;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
  }

  .Myads_EditAas{
    height: 40px;
    display: flex;
    gap: 10px;
    width: 100%;
  }
  .Myads_EditAas_icons{
    background: #D9D9D9;
    color: #C7872E;
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-right: 10px;
  }
/* -------------------slider------------------------- */

.MyAds_images_div {
    width: 30%;
}

.MyAds_details_container_div {
    width: 70%;
}

.MyAds_roomdetails_more_div {
    gap: 200px;
    padding: 10px;
}

/* 1800px screen */
@media screen and (max-width: 1800px) {
    .MyAds_images_div {
        width: 30%;
    }
    .MyAds_details_container_div {
        width: 70%;
    }
    .MyAds_roomdetails_more_div {
        gap: 200px;
        padding: 10px;
    }
}

/* 1520px screen */
@media screen and (max-width: 1520px) {
    .MyAds_images_div {
        width: 35%;
    }
    .MyAds_details_container_div {
        width: 65%;
    }
    .MyAds_roomdetails_more_div {
        gap: 200px;
        padding: 10px;
    }
    .MyAds_detailstype_div > p {
        font-size: 12px;
    }
    .MyAds_contactowner_button {
        width: 30%;
        margin-top: 20px;
        margin-bottom: 5px;
        padding: 1px;
    }
    .MyAds_contactowner_button > span {
        font-size: 9px;
    }
    #OwnerIcon {
        font-size: 22px;
    }
    .MyAds_verified {
        padding: 5px 10px;
        gap: 5px;
        font-size: 15px;
    }
    .MyAds_verified p {
        font-size: 13px;
    }
    .MyAds_detailstype_div > div {
        height: 20px;
    }
    .Myads_EditToActive {
        position: absolute;
        right: 2%;
        top: 21%;
        z-index: 1;
    }
    .Myads_EditToActive > button,
    .Myads_EditAas > button,
    .Myads_EditAas_button {
        border-radius: 6px;
        padding: 3px 5px;
        font-size: 12px;
    }
    .Myads_EditAas {
        height: 30px;
    }
    .Myads_EditAas_icons {
        padding: 3px;
        width: 20px;
        height: 20px;
        border-radius: 50px;
        margin-right: 10px;
    }
}

/* 1120px screen */
@media screen and (max-width: 1120px) {
    .MyAds_images_div {
        width: 50%;
    }
    .ProfileMain_profile_container_div {
        margin: 0;
    }
    .MyAds_description_div>p{
        display: none;

    }
    .sliders_container {
        width: 200px;
        height: 250px;
        box-shadow: 0px 4px 16px 3px rgba(0, 0, 0, 0.2);
        border-radius: 22px !important;
      }
      
      .sliders_carosal-div img {
        background-size: cover;
        display: flex;
        object-fit: cover;
        height: 240px;
        width: 200px;
      }
}

/* 850px screen */
@media screen and (max-width: 850px) {
    .Myads_EditAas {
        height: 25px;
    }
    .MyAds_description_div>p{
        display: none;

    }
    .MobileSearchListList_mainContainerDiv {
        background-color: #fff;
        padding-right: 20px;
    }
    .MobileSearchListList_Date {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .Myads_EditToActive {
        position: absolute;
        right: 2%;
        top: 20%;
        z-index: 1;
    }
    .sliders_container {
        width: 170px;
        height: 230px;
        box-shadow: 0px 4px 16px 3px rgba(0, 0, 0, 0.2);
        border-radius: 22px !important;
      }
      
      .sliders_carosal-div img {
        background-size: cover;
        display: flex;
        object-fit: cover;
        height: 220px;
        width: 170px;
      }
}
@media screen and (max-width: 720px) {
    .MyAds_images_div {
        width: 35%;
    }
    .Myads_EditAas {
        height: 20px;
    }
    .MyAds_details_container_div {
        width: 65%;
    }
    .MyAds_roomdetails_more_div {
        gap: 80px;
        padding: 10px;
    }
    
    .MyAds_description_div>p{  
        display: none;
    }
    .Myads_EditToActive {
        position: absolute;
        right: 2%;
        top: 10%;
        z-index: 1;
    }
    .Myads_EditToActive > button,
    .Myads_EditAas > button,
    .Myads_EditAas_button {
        border-radius: 4px;
        padding: 1px 2px;
        font-size: 8px;
    }
    .MyAds_contactowner_button {
        width: 50%;
        margin-top: 0px;
        padding: 0px;
    }
    .MyAds_container_div {
        margin-top: 0px;
        margin-left: 0px;
        gap: 10px;
    }
    .Myads_EditAas_icons {
        padding: 1px;
        width: 15px;
        height: 15px;
        border-radius: 50px;
        margin-right: 4px;
    }
    .sliders_container {
        height: 230px;
        width: 120px;
        box-shadow: 0px 4px 16px 3px rgba(0, 0, 0, 0.2);
        border-radius: 22px !important;
      }
      
      .sliders_carosal-div img {
        background-size: cover;
        display: flex;
        object-fit: cover;
        height: 220px;
        width: 120px;
      } 
}
/* 600px screen */
@media screen and (max-width: 600px) {
    .MyAds_images_div {
        width: 35%;
    }
    .Myads_EditAas {
        height: 20px;
    }
    .MyAds_details_container_div {
        width: 65%;
    }
    .MyAds_roomdetails_more_div {
        gap: 80px;
        padding: 10px;
    }
    
    .MyAds_description_div>p{  
        display: none;
    }
    .Myads_EditToActive {
        position: absolute;
        right: 2%;
        top: 5%;
        z-index: 1;
        display: none;
    }
    .Myads_EditToActive > button,
    .Myads_EditAas > button,
    .Myads_EditAas_button {
        border-radius: 4px;
        padding: 1px 2px;
        font-size: 8px;
        display: none;
    }
    .MyAds_contactowner_button {
        width: 50%;
        margin-top: 0px;
        padding: 0px;
    }
    .MyAds_container_div {
        margin-top: 0px;
        margin-left: 0px;
        gap: 10px;
    }
    .Myads_EditAas_icons {
        padding: 1px;
        width: 15px;
        height: 15px;
        border-radius: 50px;
        margin-right: 4px;
    }
    .sliders_container {
        height: 230px;
        width: 120px;
        box-shadow: 0px 4px 16px 3px rgba(0, 0, 0, 0.2);
        border-radius: 22px !important;
      }
      
      .sliders_carosal-div img {
        background-size: cover;
        display: flex;
        object-fit: cover;
        height: 220px;
        width: 120px;
      }
}

/* 400px screen */
@media screen and (max-width: 400px) {
    .Myads_EditToActive {
        position: absolute;
        right: -10px;
        top: 24px;
        z-index: 1;
        display: none;
    }
    .Myads_EditToActive > button,
    .Myads_EditAas > button,
    .Myads_EditAas_button {
        border-radius: 4px;
        padding: 1px 2px;
        font-size: 8px;
        display: none;
    }
    .sliders_container {
        height: 230px;
        width: 100px;
        box-shadow: 0px 4px 16px 3px rgba(0, 0, 0, 0.2);
        border-radius: 22px !important;
      }
      
      .sliders_carosal-div img {
        background-size: cover;
        display: flex;
        object-fit: cover;
        height: 220px;
        width: 100px;
      }
}
