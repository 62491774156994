.Page404MAinPAge{
    width: 100%;
    height: 100vh;
    background-color:#fff ;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    gap: 10px;

}
.Page404MAinPAge>img {
    width: 500px;
}
.Page404MAinPAge>h4 {
    color: #42526e;
}
.Page404MAinHere {
    color: #fc8019;
    cursor: pointer;
}
@media screen and (max-width:1500px) {
    .Page404MAinPAge>img {
        width: 400px;
    }
}
@media screen and (max-width:600px) {
    .Page404MAinPAge{

        gap: 30px;
    
    }
}
