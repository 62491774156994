.multi-range-slider {
  display: flex;
  position: relative;
  border:none !important;
  border-radius: 0 !important;
  padding: 20px 10px;
  box-shadow: none !important;
  flex-direction: column;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.multi-range-slider .bar-inner {
  background-color: #0d6efd !important;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-between;
  position: relative;
  border: none !important;
  justify-content: space-between;
  box-shadow: none !important;
}
.rangeSlider{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rangeSlider p{
 padding: 5px 10px;
}
.multi-range-slider .thumb::before {
    content: '';
    background-color: #0d6efd !important;
    position: absolute;
    width: 20px;
    height: 20px;
    border: none !important;
    /* box-shadow: 0px 0px 3px black, inset 0px 0px 5px grey; */
    box-shadow: none !important;
    border-radius: 50%;
    z-index: 1;
    margin: -8px;
    cursor: pointer;
}
.multi-range-slider .bar-right {
  box-shadow:none !important;
}
.multi-range-slider .bar-left{
  box-shadow:none !important;
}