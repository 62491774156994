input:focus {
    box-shadow: none !important;
    border: none !important;
  }
.OwnerDetails_main_container_div{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 50px 0;
    margin: 50px 0;
}
.OwnerDetails_OwnerDetails_container_div{
    gap: 30px;
    margin-bottom: 120px;
}
.OwnerDetails_Owner_div,.OwnerDetails_OwnerName_div{
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: flex-start;
    gap: 10px;

}
.OwnerDetails_Owner_div>p{
    font-size: 18px;
    line-height: 25px;
    color: #666666;
}
.OwnerDetails_Owner_div>img{
    width: 76.3px;
    height: 99.68px;
}
.OwnerDetails_Owner_div>span{
    font-size: 14px;
    line-height: 19px;
    color: #3496D9;
}
.OwnerDetails_OwnerName_div>div>p{
    font-size: 14px;
    line-height: 19px;
    color: #3496D9;
}
.OwnerDetails_OwnerName_div>div>span{
    background-color: #FFFFFF;
    border-radius: 15.5px;  
    border: 1px solid #D69026;
    color: #3496D9;
    padding: 2px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 30px;
}
.OwnerDetails_Ownerlable{
    font-size: 11px;
    line-height: 15px;
    color: #999999 !important;
    padding: 5px 10px ;

}
.OwnerDetails_OwnerName_div>div>button{
    background-color: #59CDB5;
    border-radius: 5px;
    color: #fff;
    padding: 10px 5px;
    font-size: 16px;
line-height: 22px;
margin-top: 30px;
}

.OwnerDetails_ContactDetails_container_div{
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: flex-start;
    gap: 10px;
}
.OwnerDetails_ContactDetails_container_div>p{
    font-size: 18px;
    line-height: 25px;
    color: #999999;
}
.OwnerDetails_ContactDetails_div{
    justify-content: space-between;
    width: 50%;
    gap: 5px;
    font-size: 14px;
    line-height: 19px;
    color: #666666;
    margin-top: 30px;
}

.OwnerDetails_Individual_div>input,.OwnerDetails_Dealer_div>input{
    margin-right: 1px;
}
.OwnerDetails_contactUser_div{
   width: 600px;
   gap: 20px;
   align-items: flex-start;
   justify-content: space-between;
   margin: 5px 0;
}
.OwnerDetails_Phone_div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.OwnerDetails_nameEmailPhone_div{
    width: 50%;
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.OwnerDetails_nameEmailPhone_div>input{
    width: 100%;
    background-color:#fff ;
    border-radius: 0px;
    padding: 3px;
}
.OwnerDetails_countarycode_input{
    width: 111px !important;
    background-color: #fff;
    border-radius: 0px;
}
.OwnerDetails_countarycode_input::placeholder{
    color: #000;
}
.OwnerDetails_phone_input{
    width: 175px ;
    background-color: #fff;
    border-radius: 0px;
    padding: 3px;

}
.OwnerDetails_textarea_input{
    width: 290px;
    height: 150px;
    display: inline-block;
    border: none !important;
    font-size: 20px;
    resize: none;
    border-radius: 0px;


}
.OwnerDetails_textarea_input_div{
    display: flex;
    justify-content: end;
    align-items: flex-end;
    flex-direction: column;
    gap: 10px;
    font-size: 10px;
    line-height: 14px;
    text-align: right;
    color: #5E5E5E;
}
.OwnerDetails_TermsAndConditions{
    display: flex;
    gap: 10px;
    font-size: 14px;
    line-height: 19px;
}
.OwnerDetails_TermsAndConditions>div>span{
    color: #3498DB;  
}
.OwnerDetails_message_button{
    background-color: #3498DB;
    border-radius: 5px;
    color: #fff;
    margin: 10px 0;
    padding: 5px 10px;
    font-size: 16px;
    line-height: 22px;
}
@media screen and (max-width:1520px) {
    .OwnerDetails_main_container_div{
        padding: 30px 0;
        margin: 30px 0;
    }
    .OwnerDetails_OwnerDetails_container_div{
        gap: 20px;
        margin-bottom: 100px;
    }
    .OwnerDetails_Owner_div,.OwnerDetails_OwnerName_div{
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        align-items: flex-start;
        gap: 10px;
    
    }
    .OwnerDetails_Owner_div>p{
        font-size: 14px;
        line-height: 25px;
        color: #666666;
    }
    .OwnerDetails_Owner_div>img{
        width: 56.3px;
        height: 79.68px;
    }
    .OwnerDetails_Owner_div>span{
        font-size: 11px;
        line-height: 19px;
        color: #3496D9;
    }
    .OwnerDetails_OwnerName_div>div>p{
        font-size: 11px;
        line-height: 19px;
        color: #3496D9;
    }
    .OwnerDetails_OwnerName_div>div>span{
        border-radius: 50px;  
        font-size: 11px;
        gap: 20px;
        margin-top: 30px;
    }
    .OwnerDetails_Ownerlable{
        font-size: 9px;   
    }
    .OwnerDetails_OwnerName_div>div>button{
        padding: 3px 5px;
        font-size: 11px;
    }
    
    .OwnerDetails_ContactDetails_container_div{
        gap: 10px;
    }
    .OwnerDetails_ContactDetails_container_div>p{
        font-size: 13px;
    }
    .OwnerDetails_ContactDetails_div{
        font-size: 11px;
        line-height: 19px;
        color: #666666;
        margin-top: 20px;
    }
    
    .OwnerDetails_Individual_div>input,.OwnerDetails_Dealer_div>input{
        margin-right: 1px;
        width: 15px;
    }
    .OwnerDetails_contactUser_div{
       width: 400px;
       gap: 10px;
    }
    .OwnerDetails_Phone_div{
        gap: 5px;
    }
    .OwnerDetails_nameEmailPhone_div{
        width: 50%;
        display: flex;
        gap: 5px;
        flex-direction: column;
    }
    .OwnerDetails_nameEmailPhone_div>input{
        width: 100%;
        background-color:#fff ;
        min-height: 33px !important;
        font-size: 11px;
        padding: 3px;
    }
    .OwnerDetails_countarycode_input{
        width: 85px !important;
        background-color: #fff;
        font-size: 11px;
        min-height: 33px !important;
    }
    .OwnerDetails_countarycode_input::placeholder{
        color: #000;
        font-size: 11px !important;

    }
    .OwnerDetails_phone_input::placeholder,.OwnerDetails_nameEmailPhone_div>input::placeholder{
        font-size: 11px !important;
    }
    .OwnerDetails_phone_input{
        width: 110px ;
        min-height: 33px !important;
        background-color: #fff;
        font-size: 11px;
        
    
    }
    .OwnerDetails_textarea_input{
        width: 200px;
        height: 110px;
        font-size: 11px;
    
    }

    .OwnerDetails_TermsAndConditions{
        gap: 10px;
        font-size: 11px;
        line-height: 19px;
    }
    .OwnerDetails_message_button{
        margin: 10px 0;
        padding: 3px 5px;
        font-size: 11px;
        line-height: 22px;
    }
}