* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#option {
  background-color: #0d6efd;
  color: white !important;
}

.Usersearch_main_container_div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Usersearch_container_div {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  z-index: 2;
}
.Usersearch_property_container_div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.Usersearch_property_container_div > div {
  padding: 13px 20px;
  cursor: pointer;
}
.Usersearch_property_container_div > div > a {
  font-size: 12px;
  color: #42526e;
  cursor: pointer;
}
.Usersearch_property_container_div > div:hover {
  background-color: #0d6efd;
  color: white !important;
}
.Usersearch_search_container_div {
  display: flex;
  box-shadow: 0px 0px 10px -1px rgb(0 0 0 / 60%);
  border-radius: 5px 5px 5px 5px !important;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
  background-color: #fff;

}
#Usersearch_span {
  border-radius: 10px;
  padding: 0px 2px;
  font-size: 8px;
  color: tomato;

  animation: blinking 1.5s infinite;
}

#Usersearch_span {
  border-radius: 10px;
  padding: 0px 2px;
  font-size: 8px;
  color: tomato;

  animation: blinking 1.5s infinite;
  /* font-size: 20px; */
}
#Usersearch_span {
}
@keyframes blinking {
  0% {
    color: red;
    background-color: rgb(5, 12, 85);
  }
  47% {
    color: #fff;
  }
  62% {
    color: transparent;
  }
  97% {
    color: transparent;
  }
  100% {
    color: #000;
    background-color: #0d6efd;
  }
}
.Usersearch_selectoption {
  border: none;
  padding: 10px;
  font-size: 12px;
  color: #42526e;
  width: 150px;
  cursor: pointer;
  border-left: 2px solid rgb(255, 255, 255) !important;
  border-top: 2px solid rgb(255, 255, 255) !important;
  border-bottom: 2px solid rgb(255, 255, 255) !important;
  border-right: 1px solid rgb(223, 217, 217) !important;
}
.Usersearch_input {
  background-color: #fff;
  padding: 5px;
}
.Usersearch_getlocation_container_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #0d6efd;
  padding: 0px;
  cursor: pointer;
}
.Usersearch_voiceicon {
  font-size: 25px;
  cursor: pointer;
}
.Usersearch_getlocation_container_div > p {
  font-size: 12px;
}
.Usersearch_locationicon {
  font-size: 20px;
  cursor: pointer;
}
.Usersearch_searchbutton {
  font-size: 12px;
  cursor: pointer;
  margin-right: 4px;
  margin: 0 7px;
}

.Usersearch_search_mobile_container_div {
  display: none;
}
.Usersearch_search_transcript_container_div {
  position: absolute;
  top: 0px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 30, 49, 0.75);
  z-index: 10000;
}


.Usersearch_selectoption {
  border: none;
  padding: 10px;
  font-size: 12px;
  color: #42526e;
  width: 150px;
  cursor: pointer;
  border-left: 2px solid rgb(255, 255, 255) !important;
  border-top: 2px solid rgb(255, 255, 255) !important;
  border-bottom: 2px solid rgb(255, 255, 255) !important;
  border-right: 1px solid rgb(223, 217, 217) !important;
}
.Usersearch_input {
  background-color: #fff;
}
.Usersearch_getlocation_container_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #0d6efd;
  padding: 0px;
  cursor: pointer;
  margin-right: 6px;
}
.Usersearch_voiceicon {
  font-size: 25px;
  cursor: pointer;
}
.Usersearch_getlocation_container_div > p {
  font-size: 12px;
}
.Usersearch_locationicon {
  font-size: 20px;
  cursor: pointer;
}
.Usersearch_searchbutton {
  font-size: 12px;
  cursor: pointer;
  margin-right: 4px;
  margin: 0 7px;
}

.Usersearch_search_mobile_container_div {
  display: none;
}

.Usersearch_search_transcript_div {
  width: 40%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;

  background-color: rgba(0, 30, 49, 1);
}
.Usersearch_search_transcript_div > img {
  width: 200px;
  margin: 5px;
}
.Usersearch_property_Mobilecontainer_div{
  display: block;
  position: absolute;
  background-color: #fff;
  left: 0;
  top:45px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  width: 23%;

}
.Usersearch_searchSelect_container_div{
  min-width: 110px;
  padding: 0 5px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  color: #42526e;
  font-size: 12px;
  cursor: pointer;
}
.UsersearchMobile_property_container_button{
  font-size: 12px;
  padding: 0 5px;
  color: #42526e;
  border-radius: 2px;
}
.UsersearchMobile_property_container_button:hover{
  background-color: #c8cacd;
transition: all 100ms ease;
}
@media screen and (max-width: 992px) {
  .hiddenone {
    display: none;
    margin-top: 50px;
    margin-right: -50px;
    transition: all 4s ease;
    -webkit-transition: all 4s ease;
  }
}
@media screen and (max-width: 600px) {
  .Usersearch_container_div {
    display: none;
  }
  .Usersearch_search_mobile_container_div {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
