input[type="number"]:focus {
  -webkit-appearance: none;
}

.inpContainer {
  position: relative;
  width: 50%;
  height: 3rem;
}
.container {
  padding: 1% 3%;
}
.inputDivContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}
.checkBoxContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 10px auto;
}
.checkbox {
  margin: auto 10px;
}
.maintenanceSelectDiv {
  width: 50%;
  height: 3rem;
  position: relative;
}
.maintenanceSelect {
  position: absolute;
  top: 2px;
  right: 5px;
  z-index: 2;
  border: none !important;
  outline: none !important;
  width: fit-content;
  bottom: 2px;
}
.gap {
  row-gap: 12px;
}

.ownerSelected {
  background-color: var(--selected-bg-color);
  color: black;
  border: 1.7px solid var(--selected-bdr-color) !important;
  min-height: fit-content;
  margin: 4px 4px;
  border-radius: 50px;
  padding: 6px 10px;
}
.ownerSelected:hover {
  background-color: var(--selected-bg-color);
  color: black;
  border: 1.7px solid var(--selected-bdr-color) !important;
}
.ownerSelected:focus {
  border: 1.7px solid var(--selected-bdr-color) !important;
  box-shadow: none !important;
}
.pricesPerMonth{
  justify-content: flex-start;
  color: gray;
}
/* ------------------------For responsiveness--------------------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 630px) {
  textarea {
    width: 100%;
  }
  .inpContainer {
    width: 100%;
  }
  .maintenanceSelectDiv {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
