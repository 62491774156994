.Handpick_main_container_div {
    width: 100%;
}

.Handpick_container_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 140px;

}

.Handpick_allimage_container_div {
    display: flex;
    gap: 10px;
    /* margin-right: 30px; */
    overflow-x: scroll;
    overflow-y: hidden;
    width: 860px;
    white-space: nowrap;
    padding: 5px 0px 10px 10px;

}

.Handpick_heading_para>h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
}

.Handpick_heading_para>p {
    font-size: 11px;
    line-height: 28px;
    color: #8993A4;
}

.Handpick_imagediv {
    width: 250px;


}

.Handpick_image_container_div {
    /* width: 300px; */
    cursor: pointer;
}

.Handpick_image_container_div:hover {
    background-color: #a7c4d5;
    color: #fff;
}

#Handpick_logoimage {
    width: 50px;
    border-radius: 50px;
}

#Handpick_bigimage {
    width: 400px !important;
    height: 170px;
    border-radius: 10px;
    object-fit: cover;

}

.Handpick_imagediv {
    position: relative;
}

.Handpick_imagediv>p {
    position: absolute;
    top: 0;
    color: white;
    /* background: #B00AB6; */
    border: 1px solid #B00AB6;
    border-radius: 2px;
    margin-top: 20px;
    font-size: 11px;
    padding: 3px;
    animation: blinking 1.5s infinite;
}

.Handpick_imagediv {}

@keyframes blinking {
    0% {
        color: red;
        background-color: rgb(5, 12, 85);
    }

    47% {
        color: #fff;
    }

    62% {
        color: transparent;
    }

    97% {
        color: transparent;
    }

    100% {
        color: #000;
        background-color: #B00AB6;
    }
}

#Handpick_logoimage {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.Handpick_paragraph_explore {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
    padding: 5px 0px;
}

.Handpick_paragraph_bare {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    /* width: 200px; */
    padding: 5px 0px;
    color: #8993A4;
}

.Handpick_paragraph_price {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
}

.Handpick_allimage_container_div::-webkit-scrollbar-thumb {
    background-color: #004F5A;
    font-weight: 300;
    scrollbar-width: none;
    scrollbar-width: thin;
}

/* .Handpick_allimage_container_div::-webkit-scrollbar-thumb:hover{
    background: #555; 
} */
.Handpick_allimage_container_div::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width:992px) {
    .Handpick_container_div {
        margin: 20px;
    }
}

@media screen and (max-width:830px) {
    .Handpick_allimage_container_div {

        /* margin-right: 0px; */
        width: 700px;

    }
}

@media screen and (max-width:800px) {
    .Handpick_allimage_container_div {

        margin-right: 0px;
        width: 580px;

    }
}

@media screen and (max-width:600px) {
    .Handpick_main_container_div {
        display: flex;
        align-items: center;
    }

    .Handpick_container_div {
        margin: 20px;
    }

    .Handpick_allimage_container_div {

        width: 350px;
    }

    /* .Handpick_allimage_container_div {
        margin-left: 25px;
    } */
}