

.rce-avatar-container{
    border-radius: 30px;
}

.ChatInitiate_Image{
    width: 60px;
    height: 60px;
}
.ChatInitiate_pragraph{
    width: 100%;
    height: 100px;
    font-size: 18px;
    font-weight: 600;
    background-color: #FFEBE2;
    border: 6px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 14px 1px rgb(0 0 0 / 25%);
    border-radius: 5px;
    padding: 8px;
    color: #5b6474;
}

@media screen and (max-width:1366px) {
    .ChatInitiate_Image{
        width: 60px;
        height: 60px;
    }
    .ChatInitiate_pragraph{
        height: 80px;
        font-size: 15px;
        font-weight: 600;
        background-color: #FFEBE2;
        border: 4px solid #FFFFFF;
        box-sizing: border-box;
        box-shadow: 0px 4px 14px 1px rgb(0 0 0 / 25%);
        border-radius: 5px;
        padding: 5px;
        color: #5b6474;
    }
}
