.UserServices_main_container_div{
    padding: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.UserServices_container_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 15px 30px;
    height: 90px;
    background: #A500F3;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.borderRadious{
    border-radius: 20px;
}
.UserServices_service_div,
.UserServices_Approve_div,
.UserServices_Approve{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.UserServices_service_div{
    width:270px ;
    margin-left:20px ;
}
.UserServices_service_div>p{
    font-weight: 700;
    font-size: 28px;
    display: flex;
    align-items: center;
    color: #FEFEFF;
    text-transform: capitalize;
}
.UserServices_service_div>div{
 width: 70px;
 height: 70px;
 border-radius: 50px;
background-color: #9C5B38;
border: 6px solid #FFFFFF;
text-align: center;
overflow: hidden;
}
.UserServices_Approve_div{
    width:300px ;
    margin-right:20px ;
}
.UserServices_Approve{
    width: 221px;
    height: 55px;
    background-color: #FFFFFF;
    border-radius: 50px;
    justify-content: space-around;
    cursor: pointer;
}
.UserServices_Approve>p{
    font-weight: 700;
    font-size: 28px;
}
.UserServices_Approve>img{
    width: 40px;
}
.UserServices_expend{
    width: 50px;
    height: 50px;
    background-color: #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    border-radius: 50px;
    color: #808080;
    cursor: pointer;
}
/* ---------------------expend----------------- */

.UserServices_Expend_container_div{
    width: 100%;
    background-color: #F2F0FF;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.UserServices_Expend_container{
  display: flex;
}
.UserServices_Expend_container>h6{
    width: 45%;
    font-weight: 500;
    font-size: 25px;
    display: flex;
    align-items: center;
}
.UserServices_Expend_container>div{
    width: 55%;
}
.UserServices_Expend_container>div>p{
    width: 70% ;
    padding: 20px;
    background-color: #FFFFFF;
    border: 0.5px solid #006AC2;
    border-radius: 9px;
    font-size: 21px;
    opacity: 0.5;
}
.UserServices_citiesCertificate_container{
    display: flex;
    width: 100%;
    /* gap: 30px; */
}
.UserServices_Certificate_container{
    width: 45%;
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.UserServices_FSSAICertificate_container{
    width: 350px;
    border: 1px dotted #A500F3;
    background-color: #F7F5F5;
    border-radius: 15px;
    display: flex;
    padding: 10px;
    gap: 20px;
}
.UserServices_FSSAICertificate_container>p{
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #545E6B;
}
.UserServices_VscFilePdf{
    color:  #ED6823;
    font-size: 60px;
}
.UserServices_cities_container{
    width: 55%;
}
.UserServices_cities_container>div{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.UserServices_city_container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 6px;
    background-color: #FFFFFF;
    border: 1px dashed #0078DB;
    border-radius: 10px;
    font-size: 20px;
    color: #696F79;
    height: 50px;
}
.UserServices_RiDeleteBinFill{
    color: #FA0000;
    cursor: pointer;
}
.UserServices_image_container{
   
margin: 50px 0;
}
.UserServices_image_container>img{
    width: 70px;
    height: 70px;
    background-color: #F7F5F5;
    border: 2px dashed #006AC2;
    border-radius: 10px;

}
.UserServices_adminApproved_container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}
.UserServices_adminApproved{
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 20px;
   background: #A500F3;
   padding: 15px 25px;
   border-radius: 10px;
   position: relative;
}
.UserServices_ChangeStatus>h5{
    font-size: 30px;
    font-weight: 700;
   color: white;
}
.UserServices_ApproveMenu{
    position: absolute;
    background-color: #D9D9D9;

   padding: 15px 25px;
   border-radius: 15px;
   display: none;
   justify-content: center;
   align-items: center;flex-direction: column;
   gap: 10px;
   right: 0;
   top: 80px;
}
.UserServices_adminApproved:hover .UserServices_ApproveMenu{
    display: flex !important;
}
/* -------------------pdf--------------------------- */
.PDFS_base64_main{
position: absolute;
width: 100%;
height: 100vh;
}
@media screen and (max-width:1366px) {
    .UserServices_main_container_div{
        padding: 20px;
        gap: 10px;
    }
    .UserServices_container_div{
        gap: 10px;
        width: 100%;
        padding: 15px 30px;
        height: 60px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .borderRadious{
        border-radius: 10px;
    }
    .UserServices_service_div,
    .UserServices_Approve_div,
    .UserServices_Approve{
        gap: 6px;
    }
    .UserServices_service_div{
        width:170px ;
        margin-left:0px ;
    }
    .UserServices_service_div>p{
        font-size: 18px;
    }
    .UserServices_service_div>div{
     width: 50px;
     height: 50px;
     padding: 2px;
    border: 3px solid #FFFFFF;
    }
    .UserServices_Approve_div{
        width:200px ;
        margin-right:0px ;
    }
    .UserServices_Approve{
        width: 150px;
        height: 35px;
        padding: 6px;
        justify-content: space-between;
    }
    .UserServices_Approve>p{
        font-size: 18px;
    }
    .UserServices_Approve>img{
        width: 23px;
    }
    .UserServices_expend{
        width: 30px;
        height: 30px;
        font-size: 30px;
    }
    /* ---------------------expend----------------- */

.UserServices_Expend_container_div{
    width: 100%;
    background-color: #F2F0FF;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 30px;
    gap: 10px;
}

.UserServices_Expend_container>h6{
    font-size: 16px;
}
.UserServices_Expend_container>div>p{
    padding: 10px;
    border-radius: 5px;
    font-size: 13px;
    opacity: 0.5;
}

.UserServices_Certificate_container{
  gap: 10px;
}
.UserServices_FSSAICertificate_container{
    width: 300px;
    border-radius: 8px;
    padding: 10px;
    gap: 10px;
}
.UserServices_FSSAICertificate_container>p{
    font-size: 18px;
    line-height: 22px;
}
.UserServices_VscFilePdf{
    font-size: 40px;
}
.UserServices_cities_container>div{
    gap: 10px;
}
.UserServices_city_container{
    padding: 6px;
    gap: 4px;
    border: 0.5px dashed #0078DB;
    border-radius: 6px;
    font-size: 13px;
    height: 30px;
}
/* .UserServices_RiDeleteBinFill{
    color: #FA0000;
} */
.UserServices_image_container{
   
margin: 30px 0;
}
.UserServices_image_container>img{
    width: 50px;
    height: 50px;
    border: 1px dashed #006AC2;
    border-radius: 6px;

}


.UserServices_adminApproved{
   gap: 10px;
   padding: 5px 10px;
   border-radius: 5px;
}
.UserServices_ChangeStatus>h5{
    font-size: 20px;
    font-weight: 700;
   color: white;
}
.UserServices_ApproveMenu{
   padding: 10px 10px;
   border-radius: 5px;
   display: none;
   justify-content: center;
   align-items: center;flex-direction: column;
   gap: 10px;
   right: 0;
   top: 45px;
}
.UserServices_adminApproved:hover .UserServices_ApproveMenu{
    display: flex !important;
}

}