/* --------------------------Common Style in PropertyProfile Componets------------------------------ */
:root {
  --selected-bg-color: #b2cdf540;
  --selected-bdr-color: #70a4f3;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.container {
  padding: 1% 3%;
}
.gap {
  /* column-gap: 1.5rem; */
  gap: 1rem;
}
/* --------------------------RoomDetails Componets Style------------------------------ */
.label {
  font-size: 14px;
  color: #6c757d;
  margin-right: 8px;
}
.RDselect {
  border: 1px solid #6c757d !important;
  border-radius: 4px;
}
/* --------------------------AreaDetails Componets Style------------------------------ */

/* .label class use in room details section   */
.inputContainer {
  border: 1px solid #0061f7;
  padding: 2px;
  border-radius: 5px;
  width: 60%;
}
.input {
  border: none !important;
  background-color: white;
  width: 100%;
}
.input:hover,
.inputBtn:hover {
  border: none !important;
}
.input:focus,
.inputBtn:focus {
  border: none !important;
  box-shadow: none !important;
}
.input:focus-visible,
.inputBtn:focus-visible {
  outline: none !important;
}
.select {
  border: none !important;
  border-radius: none !important;
  outline: none !important;
  background-color: transparent;
  border-left: 1px solid #0061f7 !important;
  padding: 5px;
  margin-right: 10px;
}
/* .option{

} */

/* --------------------------OthersRoom Componets Style------------------------------ */

.span {
  font-size: 14px;
  color: #6c757d;
}
.inputDiv {
  background-color: white;
  color: #6c6c6c;
  border: 1.2px solid lightgrey;
  border-radius: 25px;
  padding: 5px 10px;
  transition-delay: 0ms;
}
.inputDiv:hover {
  background-color: lightgrey;
  border: 1.2px solid gray;
  color: black;
}
.inputBtn {
  min-height: fit-content !important;
  cursor: pointer;
  background-color: transparent;
  border: none !important;
  border-radius: 0px !important;
  font-size: 14px;
  color: inherit;
  transition-delay: 0.3s;
}
.selected {
  border: 1.2px solid var(--selected-bdr-color) !important;
  background-color: var(--selected-bg-color);
  box-shadow: none !important;
  color: black;
}
/* .inputBtn:hover , inputBtn:focus , .inputBtn:focus-visible ----> use in Area Details Section*/

/* --------------------------Furnishing Component Style------------------------------ */
.inputBtn2 {
  min-height: fit-content !important;
  font-size: 14px;
  background-color: white;
  color: #6c6c6c;
  border: 1.2px solid gray !important;
  border-radius: 25px;
  padding: 5px 13px;
}
.inputBtn2:hover {
  background-color: lightgrey;
  color: black;
}
.inputBtn2:focus {
  border: 1.2px solid var(--selected-bdr-color) !important;
  background-color: var(--selected-bg-color);
  box-shadow: none !important;
  color: black;
}
.FurnishingItemContainer {
  position: relative;
  width: 80%;
  padding: 25px;
  margin: 15px 0px 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(128, 128, 128, 0.605);
}
.buttonItemsDiv {
  width: 100%;
  padding: 1%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* column-gap: 10px; */
}
.buttonItem {
  width: 50%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.PlusCircle {
  font-size: 24px;
  color: #0061f7;
  cursor: pointer;
}
.PlusCircle:hover {
  box-shadow: 0px 0px 8px #0063f786;
  border-radius: 50%;
}
.splSpan {
  min-height: 25px !important;
  background-color: transparent;
  border-radius: none !important;
  font-size: 12px;
  width: 30px;
  text-align: center;
  padding: 2px;
  margin: auto 8px;
  user-select: none;
}
.Selected {
  border: 1.2px solid var(--selected-bdr-color) !important;
  background-color: var(--selected-bg-color);
  box-shadow: none !important;
  color: black;
  min-height: fit-content !important;
  font-size: 14px;
  border-radius: 25px;
  padding: 5px 13px;
}
.MdCloses{
  position: absolute;
  right: 20px;
  top: 20px;
  color: rgb(186, 4, 4);
  font-size: 30px;
  cursor: pointer;
}
/* --------------------------ReserveParking Component Style------------------------------ */
.splInput {
  min-height: 25px !important;
  max-width: fit-content;
  background-color: transparent;
  border-radius: 50% !important;
  font-size: 14px;
  width: 40px;
  text-align: center;
  padding: 2px;
}
.splBtn {
  height: 25px;
  width: 25px;
  padding: 3px;
  margin: 4px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border: 1.5px solid #0061f7;
}
.splBtn:hover {
  box-shadow: 0px 0px 8px #0063f786;
}
.splBtn:focus {
  outline: none;
  border: 1.5px solid #0061f7 !important;
}
.splBtn:focus-visible {
  outline: none;
  border: 1.5px solid #0061f7 !important;
}
.splP {
  margin: auto;
  padding: 3px;
  margin-right: 4px;
}

/* --------------------------Availability Status Componets Style------------------------------ */
.selectDate {
  border: 1px solid #0061f7 !important;
  padding: 8px;
  width: 40%;
}

/* ------------------------FloorDetails Style---------------------------- */
.inputFloors {
  width: 100%;
  /* margin-right: 2rem; */
  background-color: white;
  border: 1px solid #0061f7 !important;
}
.selectFloor {
  border: 1px solid #0061f7 !important;
  /* margin-left: 2rem; */
  padding: 4px;
}
/* ------------------------------------------------------------------------ */
/* ------------------------For responsiveness--------------------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .buttonItemsDiv {
    flex-direction: column;
    align-items: center;
  }
  .splBtn {
    width: 20px;
    height: 20px;
  }
  .buttonItem {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .FurnishingItemContainer {
    width: 100%;
    padding: 10px;
    margin: 15px 0px 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(128, 128, 128, 0.605);
  }
  .splP {
    font-size: 12px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
