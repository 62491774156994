.TermAndCondition_main_container_div{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: 10005;
    background-color: rgba(145, 193, 194, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}
.TermAndCondition_cancel_div{
    position: absolute;
    top: -5px;
    left: 0px;
    /* color: red; */
    font-size: 40px;
    width: 60px;
    padding: 10px;
    height: 60px;
    /* background: #FFFFFF; */
    /* border: 0.5px solid #FE0000; */
    border-radius: 50px;
    cursor: pointer;
    z-index: 3;
}
.TermAndCondition_container_div{
    width: 70%;
    background-color: #D9D9D9;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    position: relative;
    height: 88vh;
    overflow: hidden;

}
.TermAndCondition_message_div{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: content-box !important;
    background-color: #D9D9D9;
background-color: #fff;
border-radius: 5px;
padding: 5px;
}
@media screen and (max-width:1520px) {
    .TermAndCondition_container_div{
        /* width: 80%px; */
        /* min-height: 300px; */
        border-radius: 10px;
        padding: 20px;
    }
    .TermAndCondition_main_container_div{
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        z-index: 10005;
    }
    .TermAndCondition_cancel_div{
        /* top: -10px;
        right: -10px; */
        font-size: 215px;
        width: 30px;
        padding: 5px;
        height: 30px;
    }
}
@media screen and (max-width:600px) {
    .TermAndCondition_container_div {
        width: 97%;
        /* min-height: 340px; */
        border-radius: 5px;
        padding: 20px;
    }
    .TermAndCondition_cancel_div{
        /* top: -10px;
        right: -5px; */
        font-size: 215px;
        width: 30px;
        padding: 5px;
        height: 30px;
    }
}