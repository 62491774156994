.Cities_main_container_div {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 10px; */
    margin: 20px 10px;

}

.Cities_para_topcity {
    font-size: 8px;
    color: #8993A4;
}

.Cities_para_explorecity {

    font-weight: 400;
    font-size: 25px;
    line-height: 52px;



    color: #041533;
}

.Cities_container_div {
    /* width: 50%; */
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
    margin: 20px 0;
    gap: 20px;
}

.Citiesdata_container_div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.Citiesdata_container_div:hover {
    color: #fff !important;
    background-color: #ff8937;
}
.Citiesdata_image_container_div{
    flex: 1;
}
.Citiesdata_paragraph{
    flex: 1;
}
.Citiesdata_image_container_div>img {
    width: 85px;
    height: 80px;
    padding: 5px;
    border-radius: 10px;
    object-fit: cover;
}

.Citiesdata_paragraph_name {
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    /* color: #041533; */
}

.Citiesdata_paragraph_proporties {
    font-size: 9px;
    line-height: 20px;
    color: #8993A4;
}

.Citiesdata_paragraph_name:hover {
    color: #fff;
}

.Citiesdata_paragraph_proporties:hover {
    color: #fff;
}
.WarningContainer_div{
    /* max-width:700px ; */
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.WarningContainer_div>p{
    font-size: 13px;
    color: #434444;
    line-height: 17px;
}
@media screen and (max-width:800px) {
    .Cities_container_div {

        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto auto auto;
        gap: 10px;
    }
}

@media screen and (max-width:600px) {
    .Cities_container_div {
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto auto;
        gap: 5px;
    }

    .Citiesdata_container_div {
        gap: 3px;
        padding: 2px;
    }
}