* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* 
  .affix {
    top: 0;
    width: 100%;
    z-index: 9999 !important;
  }
.flex_c{
    display: flex;
    justify-content: center;
    align-items: center;
}
.grid_c{
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
}
.bg_blue{
    background-color: #0061F7;
}
.bg_og{
    background-color: #ED6823;
}
.bg_w{
    background-color: white;
}
.c_w{
    color: white;
}
.c_og{
    color: #ED6823;
}
.c_blue{
    color: #0061F7; 
}
.p_box{
    border: 1px solid rgb(155, 152, 152);
    border-radius: 30px;
    padding: 2px 5px;
    text-align: center;
    font-size: 13px;
    cursor: pointer;
}
.p_box:hover{
    border: 1px solid #ED6823;
    background-color: #ED6823;
    color: white;
    transition: all 0.35s;
}
.b_r{
    box-sizing: border-box;
    box-shadow: 2px 2px 10px -1px rgba(0, 0, 0, 0.21);
    border-radius: 10px;
    padding: 10px ;

}
------------------------------usermainpage--------------------------------------- */
.usermainpage_main_container_div {
    background-color: #F4F5F7;

}

.usermainpage_container_div {
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 120px 20px 10px 20px;
    margin-right: 4rem;



}

.usermainpage_filter_div {
    flex: 1.2;
    height: 88vh;
    width: 100%;
    /* height: 100%; */
    overflow: hidden;

}

.usermainpage_searchlist_div {
    flex: 3;
    height: 88vh;
    width: 100%;
    /* height: 100%; */
    overflow: hidden;


}

.usermainpage_side_div {
    flex: 1;

}

@media screen and (max-width:1366px) {
    .usermainpage_filter_div {
        height: 79vh;
    }

    .usermainpage_searchlist_div {
        height: 79vh;
    }
}

@media screen and (max-width:1200px) {
    .usermainpage_container_div {
        margin-right: 0rem;
    }
}

@media screen and (max-width:1102px) {
    .usermainpage_filter_div {
        display: none;
    }

    .usermainpage_container_div {
        margin-right: 0rem;
    }
}

@media screen and (max-width:850px) {
    .usermainpage_main_container_div {
        /* background-color: #fff; */
    }

    /* .usermainpage_filter_div{
        display: none;
    }
    .usermainpage_container_div{
        margin: 0;
        padding: 90px 10px 5px 10px;
    }
    .usermainpage_searchlist_div{
        height: 82vh;
    } */
}

@media screen and (max-width:600px) {
    .usermainpage_main_container_div {
        /* background-color: #fff; */
    }

    .usermainpage_filter_div {
        display: none;
    }

    .usermainpage_container_div {
        margin: 0;
        padding: 90px 10px 5px 10px;
    }

    .usermainpage_searchlist_div {
        height: 82vh;
    }
}