.Selects_main_container_div{
    width: 100%;
    height: 100vh;
    height:fit-content;
    position: relative;
}
.Selects_name{
    width:100%;
    padding:0 10px ;
    justify-content: space-between !important;
    cursor: pointer;
    height: inherit;
    justify-content: center;
    display: flex;
    align-items: center;
}
.Selects_options_value{
    width: 100%;
    position: absolute;
    top: 150%;
    flex-direction: column;
    background-color: #fff;
    z-index: 1;
    border: 1px solid #C5C5C5;
    border-radius: 5px;
    margin-top: 5px;
}
.Selects_options_value option{
    background-color: #F3F3F3;
    width: 100%;
    border-radius: 3px;
    display: flex;
    align-items: center;
    margin: 1px;
    padding:5px 10px;
    cursor: pointer;
}
.select{
    background-color: #0d6efd !important;
    color: #fff;
}
.Selects_options_value option:hover{
    background-color: #E6E6E6;
}
.sideborder{
  color: #ddd;
}