.ChangeEmail_Email_div{
    width: 100%;
    /* height: 400px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 60px;
    gap: 10px;
}
.ChangeEmail_main_container_div{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: 10005;
    background-color: rgba(74, 139, 140,0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}
.ChangeEmail_container_div{
    width: 1004px;
    min-height: 350px;
    background-color: #D9D9D9;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    padding: 30px;
    position: relative;
}
.ChangeEmail_tittle_div>h6{
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
}
.ChangeEmail_cancel_div{
    position: absolute;
    top: -20px;
    right: -20px;
    color: red;
    font-size: 30px;
    width: 60px;
    padding: 10px;
    height: 60px;
    background: #FFFFFF;
    border: 0.5px solid #FE0000;
    border-radius: 50px;
    cursor: pointer;
    z-index: 3;
}
.ChangeEmail_verifymail_div{
    display: flex;
    justify-content: space-between;
}
.ChangeEmail_verifymail_div>input{
    max-width: 40px;
    height: 40px;
    background-color: #fff;
}
.NewEmailSignin_button{
    width: 100px;
    color: white;
    border: 1px solid #ED6823;
    background-color: #ED6823;
    padding: 0.375rem;
    font-size: 0.7rem;
    border-Radius: 1.5rem;
}
@media screen and (max-width:1520px) {
    .ChangeEmail_Email_div{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 40px;
        gap: 10px ;
    }
    .ChangeEmail_main_container_div{
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        z-index: 10005;
    }
    .ChangeEmail_container_div{
        width: 700px;
        min-height: 300px;
        border-radius: 15px;
        padding: 20px;
    }
    .ChangeEmail_tittle_div>h6{
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
    }
    .ChangeEmail_cancel_div{
        top: -10px;
        right: -10px;
        font-size: 215px;
        width: 30px;
        padding: 5px;
        height: 30px;
    }
    .ChangeEmail_verifymail_div>input{
        max-width: 40px;
        height: 40px;
    }
}
@media screen and (max-width:600px) {
    .ChangeEmail_container_div {
        width: 97%;
        min-height: 340px;
        border-radius: 15px;
        padding: 20px;
    }
    .ChangeEmail_Email_div {
        padding: 0px;
        gap: 8px;
    }
    .ChangeEmail_cancel_div{
        top: -10px;
        right: -5px;
        font-size: 215px;
        width: 30px;
        padding: 5px;
        height: 30px;
    }
   
}