.ProfileSideMenu_main_conTainer_div{
    background-color: #FAFBFC;
box-shadow: 0px 4px 31px rgba(31, 92, 163, 0.28);
/* border-radius: 16px 16px 0px 0px; */
height: 99.5vh;
}
.ProfileSideMenu_Lists_div{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    /* gap: 10px; */
    padding: 120px 5px 0px 0px;
    margin: 5px;
}
.ProfileSideMenu_list_SelectMenu{
    background-color: #F3F3F3 !important;
    border-radius: 3px;
    padding:30px 20px;
    width: 100%;
    margin: 3px;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    display: flex;
    gap: 10px;
    align-items: center;
    color: #65676B !important;
    cursor: pointer;
}
.ProfileSideMenu_SelectMenu{
    background-color: #E7F3FF !important;
    border-radius: 3px;
    color: #1876F2 !important;
}
.ProfileSideMenu_Listsicon{
    font-size: 35px;
}


@media screen and (max-width:1520px) {
    .ProfileSideMenu_Lists_div{
        padding: 120px 3px 0px 0px;
        margin: 3px;
    }
    .ProfileSideMenu_Lists_div>a{
        padding: 10px 10px;
        margin: 3px;
        font-size: 12px;
        gap: 5px;
    }
    .ProfileSideMenu_Listsicon{
        font-size: 22px;
    }
    .ProfileSideMenu_ListParagraph{
        font-size: 13px;
    }
}
@media screen and (max-width:600px) {
    .ProfileMain_profile_container_div{
        margin-right: 0rem !important;
    }
    .ProfilePage_main_Contain_div {
        width: 100%;
        display: flex;
        gap: 20px;
        flex-direction: column;

    }
    .LikeProperty_main_container{
        background-color: #e5e5e5;
        padding: 5px;

    }
    .ProfilePage_Sideprofilemenu_Contain_div {
        width: 100%;
        /* width: 443px; */
        display: none;
    }
    .ProfileSideMenu_Lists_div {
        flex-direction: row;
        padding: 87px 5px 0px 0px;
        position: fixed;
        width: 100%;
        /* background-color: #fff; */
        z-index: 5;
    }
    .ProfileSideMenu_list_SelectMenu{
        justify-content: center;
        align-items: center;
    }
    .ProfileSideMenu_list_SelectMenu>p{
        display: none;
    }
    .ProfileSideMenu_main_conTainer_div{
        height: 13vh;
    }
    .ProfilePage_Ads_Contain_div{
        width: 100% !important;
        /* background-color: #fff; */

    }
    .ProfileBody_main_container_div {
        height: calc(100vh - 8vh) !important;
        padding: 90px 0px 0px 0px !important;
        margin-bottom: 30px !important;
    }
    .ProfileBody_container_div {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        padding-right: 17px;
        padding-top: 0px;
        box-sizing: content-box;
    }
}