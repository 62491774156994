
.property_main_container .formcheckinput{
    float: left;
    margin-left: 1.5em;
}
.formcheckinput:checked {
    background-color: #ED6823;
    border-color: #ED6823;
}
.residential_main_container_div{
    background: #FFFFFF;
border: 2px solid #EFF0F6;
box-sizing: border-box;
box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.37);
border-radius: 9px;
}
.residential_main_div{
     gap: 10px;
     margin-top: 20px;
 }
.residential_main_div>input{
    border: 4px solid #EFF0F6;
box-sizing: border-box;
box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.25);
border-radius: 4px;
height: 30px;
padding: 4px;
 min-height: 30px;
 border: none !important;
}
.residential_save_button{
    border-radius: 5px;
    background-color: #0061F7;
    padding:0px 10px;
    margin-right: 20px;
    color: white;
    height: 30px;
}
.residential_BsPlusCircle{
    font-weight: bold;
    font-size: 35px;
    color: #ED6823;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 53px
}
.residential_container_details_div{
    display: flex;
    justify-content: space-between;
    border: 2px solid #ED6823;
    width: 200px;
    height: 40px;
    box-sizing: border-box;
    box-shadow: 2px 2px 10px -1px rgba(0, 0, 0, 0.21);
    border-radius: 52px;
    color:#4A8EFA;
}
.residential_container_details_div>span{
    cursor: pointer;
}

.residential_InputFild{
    width: 120px;
    min-height: 30px;
    border-radius: 50px;
    background-color: #fff;
    padding: 5px;
    color: #4A8EFA;
}
.residential_InputFild:hover{
    background-color: rgba(237, 104, 35,0.8) !important;
    color: #ffff;
}
.residential_container_elelment_div{
    display: flex;
    flex-wrap: wrap;
    padding: 0px 0px 44px 0;
    gap: 20px;
}
.residential_container_details_div:hover{
    background-color: rgba(237, 104, 35,0.8);
    border: 2px solid rgba(237, 104, 35,0.8);
    color: #ffff;
}
.residential_selectItem{
    background-color: #ED6823;
    border: 2px solid #ED6823;
    color: #ffff;

}
.residential_hover_div{
    position: absolute;
    top: 10%;
    right: 10px;
    color: white; 
    align-items: center;
    text-align: center;
    margin: 5px;
    font-size: 20px;
    cursor: pointer;

}
.residential_hover_div:hover{
    color: #ED6823;
  
}
.residential_hover_divs:hover{
    color: #fff !important;
  
}

.residential_container_details_div>span{
    padding: 5px;
}
.residential_container_details_div:hover{
color: white;
}
.residential_reset_button{
    background-color: #ED6823;
    padding: 2px;
    font-size: 20px;
    color: white;
    transform: rotate(-180deg);
    border-radius: 50px;
    margin: 5px 15px 0px -35px;
}

.Commertial_paragraph{
    font-weight: 400;
    font-size: 16px;
    padding: 20px 30px;
}
/* -----------------------Furninshed------------------------ */
.furnished_container_main_div{
    justify-content: space-around;
}
.furnished_container_details_div{

    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 2px 2px 10px -1px rgba(0, 0, 0, 0.21);
    border-radius: 52px;
    width: 200px;
    color: #0061F7;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
    cursor: pointer;
}
.furnished_main_table_container_div{
    background-color: #FFFFFF;
border: 2px solid #EFF0F6;
box-sizing: border-box;
box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.37);
border-radius: 9px;
margin-top: 20px;
padding: 5px;
}
.furnished_table_container_div{
    background-color: white;
}
.furnished_table_container_div>thead>tr>th{
    background-color: rgb(231, 226, 226);
    border-radius: 0px;
    color: #ED6823;
}
.furnished_table_container_div>tbody>tr{
    background-color:white;
    border-radius: 0px;
    border: white;
}
.furnished_table_container_div>tbody>tr{
    background-color:white;
    border-radius: 0px;
    border: white;
}
#furnised-delete-div{
    color: #DE5462;
    cursor: pointer;
}
.furnished_main_button_container_div{
    justify-content: right;
    gap: 10px;
    margin-right: 50px;
}
.furnished_main_button_container_div>input{
    min-height: 32px;
    margin-bottom: 20px;
    padding: 5px;
    border: none !important;
}