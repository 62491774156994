/* -----------------------------------Service master----------------------------- */
.servicemaster {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center !important;
    gap: 20px;
    padding: 20px 0;
}

.sevicemaster_ButtoniconsSelect {
    box-shadow: 10px 10px 0px 0px rgb(0 0 0 / 71%);
}

.sevicemaster_Buttonicons {
    background: #ED6823;
    border: 2px solid #ED6823;
    /* box-shadow: 12px 0px 0px 0px rgb(0 0 0 / 71%); */
    border-radius: 9px;
    /* transform: rotate(-180deg); */
    color: #EFF0F6;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    gap: 15px;

}

.sevicemaster_Buttonicons>span {
    font-weight: bold;
    font-size: 25px;
    line-height: 18px;
}

.sevicemaster_main_icons {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 40px;
    align-self: center;
}

.servicemaster_BsPlusCircle {
    font-size: 50px;
    color: #ED6823;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 53px;
    cursor: pointer;
}

.servicemaster_main_div {
    padding: 30px;
    background-color: #FFFFFF;
    border: 2px solid #EFF0F6;
    box-sizing: border-box;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.37);
    border-radius: 9px;
}

.servicemaster_div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    gap: 20px;
}
.sevicemaster_imgIcon{
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.servicemaster_fasdish {
    flex: 0.3;
    border: 4px solid #EFF0F6;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.servicemaster_imageDiv{
    background-color: #EFF0F6 !important;
    /* padding: 5px 5px; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 70px !important;
}
.servicemaster_imDiv{
    background-color: #ED6823 !important;
    width: 40px !important;
    padding: 0;
}

.sevicemaster_InputDiv>input {
    background-color: white;
    justify-content: center;
    min-height: 46px;
    font-size: none;
    overflow: hidden;
}

.sevicemaster_InputDiv {
    width: 100%;
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.sevicemaster_icons {
    width: 40px;
    height: 40px;
    text-align: center;
    align-self: center;
    color: #ED6823;
}

.servicemaster_servicename {
    flex: 1;
    margin-left: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.servicemaster_servicename>p {
    font-size: 26px !important;
    line-height: 31px;
}

.servicemaster_activeaanddeactive {
    flex: .5;
}

.servicemaster_activeaanddeactive>div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    gap: 50px;
    background: #EFF0F6;
    box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.servicemaster_activeaanddeactive>div>span {
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
}

.servicemaster_opacity {
    opacity: 0.3;
}

.servicemaster_servicename>div {
    border: 4px solid #EFF0F6;
    box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.servicemaster_servicename>div>input {
    /* width: 172px; */
    height: 54px;
    background-color: white;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
}

.servicemaster_certianddoc {
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
}

.servicemaster_CertificateDocumentService {
    background-color: #ED6823;
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.servicemaster_certianddoc>div>p {
    font-size: 26px;
    line-height: 31px;
    color: #FFFFFF;
    align-self: center;
    padding: 10px;
}

.servicemaster_buttonAddDelete {
    width: 20%;
    background-color: #5B5B5B;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
}

.servicemaster_buttonAddDelete>button {
    width: 50%;
    background-color: #5B5B5B;
    padding: 15px;
    color: white;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    background-color: #5B5B5B;
}

.servicemaster_buttonAddDelete>button:focus {
    border: none !important;
}

.servicemaster_checkbox>p {
    font-size: 20px;
    line-height: 34px;
    margin: 20px;
}

.servicemaster_checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.servicemaster_CertificateBorder {
    width: 34px;
    height: 34px;
    background-color: #FFFFFF;
    border: 2px solid #84858A;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.37);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.servicemaster_Certificate_heckbox {
    width: 24px;
    height: 24px;
    background-color: #ED6823 !important;
    border-radius: 6px;
}

.servicemaster_main_container_div {
    margin-right: 10%;
    border: 2px solid #ED6823;
    box-sizing: border-box;
    box-shadow: 0px 4px 25px 1px rgba(0, 0, 0, 0.37);
    border-radius: 9px;
    margin-top: 20px;
    padding-bottom: 20px;
}

.servicemaster_hepltext_div,
.servicemaster_guidline_div {
    margin: 20px 0px;
    display: flex;
    align-items: center;
}

.servicemaster_hepl1text_div {
    align-self: center;
    padding: 10px 20% 10px 5px;
}

.servicemaster_text12 {
    padding: 20px;
    font-size: 26px;
    line-height: 31px;
}

.servicemaster_hepltext_div>span {
    flex: 0.35;
}

.servicemaster_hepltext_div>div {
    flex: 3;
}

/* .servicemaster_hepltext_div>input, */
.servicemaster_hepl1text_div {
    width: 70%;
    font-size: 20px;
    padding: 10px;
    height: 50px;
    background: #FFFFFF;
    border: 4px solid #EFF0F6;
    border-radius: 4px;

}

.servicemaster_hepltext_div>input:focus,
.servicemaster_guidline_div>input:focus {
    border: 4px solid #EFF0F6 !important;
}

.servicemaster_hepltext_div>input::placeholder,
.servicemaster_guidline_div>input::placeholder {
    flex: 3;
    border: none !important;
    font-size: 20px;
}

.servicemaster_BsFillQuestionCircleFill {
    font-size: 24px;
}

.servicemaster_guidline_div>span {
    flex: 0.35;
}

.sevicemaster_requiredper_mission {
    width: 90%;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
    background-color: #EFF0F6;
    padding: 10px 20px;
    border-radius: 9px;
}

.sevicemaster_toggle {
    width: 0px;
    background-color: #EFF0F6;
}

.form-switch .form-check-input {
    width: 2.5em;
}


.servicemaster_save_button {
    background-color: #0061F7;
    padding: 0px 10px;
    color: white;
}

.servicemaster_delete_button {
    background-color: #ED6823;
    color: white;
    padding-right: 5px;
    margin-left: 20px;

}

.sevicemaster_activedeactive_toggle {
    background-color: #EFF0F6;
    width: 0;
}

.servicemaster_RxCross2{
    color: #ED6823;
    font-size: 30px;
    cursor: pointer;
}
@media screen and (max-width: 1400px) {

    /* -----------------------------------Service master----------------------------- */
    .servicemaster {
        gap: 20px;
        padding: 20px 0;
    }

    .sevicemaster_ButtoniconsSelect {
        box-shadow: 7px 7px 0px 0px rgb(0 0 0 / 71%);
    }

    .sevicemaster_Buttonicons {
        border-radius: 6px;
        padding: 5px;
        gap: 5px;
    }

    .sevicemaster_Buttonicons>span {
        font-size: 15px;
        line-height: 14px;
    }

    .sevicemaster_main_icons {
        width: 30px;
        height: 30px;
        font-size: 25px;
        padding-top: 2px;
    }

    .servicemaster_BsPlusCircle {
        font-size: 30px;
        border-radius: 53px
    }

    .servicemaster_main_div {
        padding: 20px;
        border-radius: 6px;
    }

    .servicemaster_div {
        gap: 10px;
    }

    .servicemaster_fasdish {
        border: 3px solid #EFF0F6;
        border-radius: 4px;
        width: 30% !important;
    }


    .sevicemaster_InputDiv>input {
        min-height: 30px;
        width: 100%;
    }

    .sevicemaster_InputDiv {
        font-size: 17px;
    }

    .sevicemaster_icons {
        width: 25px;
        height: 25px;
    }

    .servicemaster_servicename {
        flex: 1;
        margin-left: 10px;
        gap: 10px;
    }

    .servicemaster_servicename>p {
        font-size: 16px !important;
        line-height: 11px;
    }

    .servicemaster_activeaanddeactive>div {
        padding: 0 10px;
        gap: 40px;
        height: 40px;
    }

    .servicemaster_activeaanddeactive>div>span {
        font-size: 16px;
        line-height: 11px;
    }

    .servicemaster_servicename>div {
        border: 4px solid #EFF0F6;
        min-height: 30px;
    }

    .servicemaster_servicename>div>input {
        width: 142px;
        height: 30px;
        border-radius: 4px;
    }

    .servicemaster_certianddoc {
        border-radius: 2px;
    }

    .servicemaster_certianddoc>div>p {
        font-size: 16px;
        line-height: 15px;
        padding: 5px;
    }

    .servicemaster_buttonAddDelete {
        font-size: 20px;
    }

    .servicemaster_buttonAddDelete>button {
        width: 50%;
        padding: 5px;
        border-radius: 3px;
    }

    .servicemaster_checkbox>p {
        font-size: 15px;
        line-height: 14px;
        margin: 10px;
    }

    .servicemaster_CertificateBorder {
        width: 20px;
        height: 20px;
        border-radius: 3px;
    }

    .servicemaster_Certificate_heckbox {
        width: 14px;
        height: 14px;
        border-radius: 3px;
    }

    .servicemaster_main_container_div {
        border: 1px solid #ED6823;
        border-radius: 6px;
        margin-top: 10px;
        padding-bottom: 10px;
    }

    .servicemaster_hepltext_div,
    .servicemaster_guidline_div {
        margin: 5px 0px;
    }

    .servicemaster_text12 {
        padding: 10px;
        font-size: 15px;
        line-height: 15px;
    }

    .servicemaster_hepl1text_div {
        font-size: 13px;
        padding: 5px;
        height: 30px;
        border: 2px solid #EFF0F6 !important;
    }

    .servicemaster_hepl1text_div:focus {
        border: 2px solid #EFF0F6 !important;
    }

    .servicemaster_hepl1text_div::placeholder {
        font-size: 13px;
    }

    .servicemaster_BsFillQuestionCircleFill {
        font-size: 17px;
    }

    .sevicemaster_requiredper_mission {
        margin: 20px 0;
        padding: 1px 10px;
        border-radius: 6px;
    }

    .sevicemaster_requiredper_mission>h3 {
        font-size: 15px;
    }

    .sevicemaster_toggle {
        width: 0px;
        background-color: #EFF0F6;
    }

    .form-switch .form-check-input {
        width: 2.5em;
    }

    .servicemaster_save_button {
        padding: 0px 10px;
        margin-top: -10px;
    }

    .servicemaster_delete_button {
        padding-right: 5px;
        margin-top: -10px;
        margin-left: 20px;
    }

}